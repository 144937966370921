<template>
  <router-view />
</template>

<style>
@import "./assets/css/flex.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #e9eef3;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.common-layout,
.el-container {
  width: 100%;
  height: 100%;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.danger {
  color: #ed5151 !important;
}
.danger:hover {
  color: #e98080 !important;
}
.search-box {
  padding: 20px;
  background: #fff;
}
.table-box {
  margin-top: 14px;
  background-color: #fff;
  padding-bottom: 10px;
  max-height: calc(100% - 165px);
  overflow-y: auto;
}
.table-btns {
  padding: 20px;
}
.pagination {
  padding: 20px;
}
.collapse-box {
  padding: 20px;
  background: #fff;
  margin-top: 14px;
  overflow-y: auto;
  max-height: calc(100% - 165px);
}
.collapse-btns{
  padding: 20px 0px;
}
.tag {
  margin-right: 7px;
}
.question {
  padding-top: 20px;
}
.analysis {
  padding-top: 7px;
  font-weight: bold;
}
.font-bold {
  font-weight: bold;
}
.el-collapse-item__header {
  line-height: normal;
  height: auto;
  padding: 20px 0;
}
</style>
