<template>
  <div class="search-box">
    <el-form
      ref="searchFormRef"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="职业" prop="userType">
        <el-select v-model="formInline.userType" placeholder="">
          <el-option label="人力资源管理师" :value="1" />
          <el-option label="健康管理师" :value="2" />
          <el-option label="数控铣工" :value="3" />
          <el-option label="钳工" :value="4" />
          <el-option label="汽车机械维修工" :value="5" />
          <el-option label="数控车床" :value="6" />
          <el-option label="电工" :value="7" />
        </el-select>
      </el-form-item>
      <el-form-item label="考级" prop="type">
        <el-select v-model="formInline.type" placeholder="请选择考级">
          <el-option label="一级" :value="1" />
          <el-option label="二级" :value="2" />
          <el-option label="三级" :value="3" />
          <el-option label="四级" :value="4" />
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formInline.name" placeholder="请输入姓名" />
      </el-form-item>
      <el-form-item label="手机号" prop="userName">
        <el-input v-model="formInline.userName" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="formInline.sex" placeholder="请选择性别">
          <el-option label="男" :value="1" />
          <el-option label="女" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="onReset(searchFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table-box">
    <div class="table-btns">
      <el-button type="primary" @click="addFn">新增</el-button>
      <el-button
        type="danger"
        :disabled="selectionTable.length == 0"
        @click="batchDelFn"
        >批量删除</el-button
      >
      <el-button type="primary" @click="uploadFn">导入</el-button>
      <!-- <el-upload
        ref="upload"
        style="display: inline-block; margin-left: 10px"
        accept=".xls,.xlsx"
        :file-list="fileList"
        :show-file-list="false"
        :headers="headers"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :before-upload="beforeUploadFile"
        :action="userImport()"
        v-loading="uploading"
        ><el-button type="primary">导入</el-button>
      </el-upload> -->
    </div>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <el-table-column type="selection" align="center" width="55" />
      <el-table-column type="index" label="序号" align="center" width="90" />
      <el-table-column prop="name" label="姓名" align="center" />
      <el-table-column prop="userTypeText" label="职业" align="center" />
      <el-table-column prop="type" label="考级" align="center">
        <template #default="scope">
          <span>{{
            ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"][scope.row.type]
          }}级</span>
        </template>
      </el-table-column>
      <el-table-column prop="sex" label="性别" align="center">
        <template #default="scope">
          <span>{{ scope.row.sex == 1 ? "男" : "女" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="手机号" align="center" />
      <el-table-column prop="userTotal" label="刷题进度" align="center">
        <template #default="scope">
          <span
            :class="
              scope.row.userTotal >= scope.row.total && scope.row.total != 0
                ? 'success'
                : ''
            "
            >{{ scope.row.userTotal }} / {{ scope.row.total }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template #default="scope">
          <el-button type="primary" size="small" @click="editFn(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" size="small" @click="delFn(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[10, 50, 100, 200]"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <el-dialog
    v-model="modelDialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :width="600"
    title="学员导入"
  >
    <modelDialog
      v-if="modelDialogVisible"
      ref="modelDialogRef"
      @dialogClose="dialogClose"
    />
  </el-dialog>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :width="600"
    :title="dialogTitle"
  >
    <userAddEditDialog
      v-if="dialogVisible"
      ref="addEditDialogRef"
      @dialogClose="dialogClose"
    />
  </el-dialog>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import userAddEditDialog from "./dialog/userAddEditDialog.vue";
import modelDialog from "./dialog/modelDialog.vue";
import { userList, userDelete } from "@/http/http";
let dialogVisible = ref(false);
let modelDialogVisible = ref(false);
let modelDialogRef = ref(null);
let dialogTitle = ref("");
let addEditDialogRef = ref(null);
const tableData = ref([]);
let selectionTable = ref([]);
const addFn = () => {
  dialogVisible.value = true;
  dialogTitle.value = "新增";
};
const editFn = (row) => {
  dialogVisible.value = true;
  dialogTitle.value = "修改";
  nextTick(() => {
    addEditDialogRef.value.init(row);
  });
};
const uploadFn = () => {
  modelDialogVisible.value = true;
};
const batchDelFn = () => {
  ElMessageBox.confirm("请确认是否删除？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    console.log(selectionTable.value);
    let ids = [];
    selectionTable.value.forEach((item) => {
      ids.push(item.id);
    });
    _userDelete(ids.join());
  });
};
const delFn = (row) => {
  ElMessageBox.confirm("请确认是否删除？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    _userDelete(row.id);
  });
};
const _userDelete = (ids) => {
  userDelete(ids).then((res) => {
    if (res.data.code == 200) {
      ElMessage({
        message: res.data.message,
        type: "success",
      });
      _userList();
    }
  });
};
const dialogClose = (flag) => {
  console.log(flag);
  dialogVisible.value = false;
  modelDialogVisible.value = false;
  if (flag) {
    onSubmit();
  }
};

const formInline = reactive({
  userType: 1,
  name: "",
  userName: "",
  sex: "",
  type: "",
});
const searchFormRef = ref();
const onSubmit = () => {
  _userList();
};
const onReset = (formEl) => {
  formEl.resetFields();
  pageSize.value = 10;
  currentPage.value = 1;
  _userList();
};
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(0);
const handleSizeChange = (val) => {
  pageSize.value = val;
  _userList();
};
const handleCurrentChange = (val) => {
  currentPage.value = val;
  _userList();
};
const handleSelectionChange = (val) => {
  selectionTable.value = val;
};
const _userList = () => {
  userList({
    size: pageSize.value,
    current: currentPage.value,
    ...formInline,
  }).then((res) => {
    tableData.value = res.data.data.records;
    total.value = res.data.data.total;
  });
};
let fileList = ref([]);
let headers = ref({
  token: $cookies.get("token"),
});
let uploading = ref(false);
const beforeUploadFile = (file) => {
  uploading.value = true;
  const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
  if (extension != "xls" && extension != "xlsx") {
    ElMessage({
      message: "只能导入excel文件",
      type: "error",
    });
    uploading.value = false;
  }
  return extension == "xls" || extension == "xlsx";
};
const uploadSuccess = (response, file, fileList) => {
  uploading.value = false;
  if (response.code == 200) {
    ElMessage({
      message: "导入成功",
      type: "success",
    });
    _userList();
  }
};
const upload = ref();
const uploadError = () => {
  uploading.value = false;
  upload.value.clearFiles();
  ElMessage({
    message: "导入失败,请尝试重新导入或联系管理员",
    type: "error",
  });
};
onMounted(() => {
  _userList();
});
</script>
<style scoped lang="scss">
.success {
  color: #02bc6c;
}
</style>
