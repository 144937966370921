<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon
  >
    <el-form-item label="职业" prop="userType">
      <el-select v-model="ruleForm.userType" placeholder="">
        <el-option label="人力资源管理师" :value="1" />
        <el-option label="健康管理师" :value="2" />
        <el-option label="数控铣工" :value="3" />
        <el-option label="钳工" :value="4" />
        <el-option label="汽车机械维修工" :value="5" />
        <el-option label="数控车床" :value="6" />
        <el-option label="电工" :value="7" />
      </el-select>
    </el-form-item>
    <el-form-item label="考级" prop="level">
      <el-select v-model="ruleForm.level" placeholder="">
          <el-option label="一级" :value="1" />
          <el-option label="二级" :value="2" />
          <el-option label="三级" :value="3" />
          <el-option label="四级" :value="4" />
      </el-select>
    </el-form-item>
    <el-form-item label="题型" prop="type">
      <el-select
        v-model="ruleForm.type"
        @change="questionTypeChange(ruleFormRef, ruleForm.type)"
        placeholder=""
      >
        <el-option label="单选" :value="1" />
        <el-option label="判断" :value="3" />
        <el-option label="多选" :value="2" />
      </el-select>
    </el-form-item>
    <el-form-item label="题目" prop="question">
      <el-input
        v-model="ruleForm.question"
        :autosize="{ minRows: 3, maxRows: 6 }"
        type="textarea"
      />
    </el-form-item>
    <el-form-item label="正确选项" prop="answer">
      <el-select
        :multiple="ruleForm.type == '2' ? true : false"
        v-model="ruleForm.answer"
        placeholder=""
      >
        <el-option label="A" value="A" />
        <el-option label="B" value="B" />
        <el-option
          v-if="ruleForm.type == '1' || ruleForm.type == '2'"
          label="C"
          value="C"
        />
        <el-option
          v-if="ruleForm.type == '1' || ruleForm.type == '2'"
          label="D"
          value="D"
        />
        <el-option v-if="ruleForm.type == '2'" label="E" value="E" />
      </el-select>
    </el-form-item>
    <el-form-item label="(A)选项一" prop="selectA">
      <el-input v-model="ruleForm.selectA" />
    </el-form-item>
    <el-form-item label="(B)选项二" prop="selectB">
      <el-input v-model="ruleForm.selectB" />
    </el-form-item>
    <el-form-item
      v-if="ruleForm.type == '1' || ruleForm.type == '2'"
      label="(C)选项三"
      prop="selectC"
    >
      <el-input v-model="ruleForm.selectC" />
    </el-form-item>
    <el-form-item
      v-if="ruleForm.type == '1' || ruleForm.type == '2'"
      label="(D)选项四"
      prop="selectD"
    >
      <el-input v-model="ruleForm.selectD" />
    </el-form-item>
    <el-form-item v-if="ruleForm.type == '2'" label="(E)选项五" prop="selectE">
      <el-input v-model="ruleForm.selectE" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="default" @click="submitForm(ruleFormRef)">
        保存
      </el-button>
      <el-button size="default" @click="closeForm">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineExpose } from "vue";
import { selectQuestionAddOrUpdate } from "@/http/http";
import { ElMessage } from "element-plus";
const formSize = ref("default");
const emit = defineEmits(["dialogClose"]);
const ruleFormRef = ref();
const ruleForm = reactive({
  userType: 1,
  type: 1,
  question: "",
  level: "",
  answer: "",
  selectA: "",
  selectB: "",
  selectC: "",
  selectD: "",
  selectE: "",
});
const init = (row) => {
  for (const key in row) {
    ruleForm[key] = row[key];
  }
  ruleForm.answer = row.answer.split("");
};
defineExpose({ init });
const rules = reactive({});
const questionTypeChange = (formEl, val) => {
  // formEl.resetFields();
  ruleForm.type = val;
};
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      ruleForm.answer =
        ruleForm.answer.constructor === Array
          ? ruleForm.answer.join("")
          : ruleForm.answer;
      selectQuestionAddOrUpdate(ruleForm).then((res) => {
        if (res.data.code == 200) {
          ElMessage({
            message: "操作成功",
            type: "success",
          });
          emit("dialogClose", true);
        }
      });
    } else {
      console.log("error submit!", fields);
    }
  });
};

const closeForm = () => {
  emit("dialogClose");
};
</script>
