<template>
  <div class="search-box">
    <el-form
      ref="searchFormRef"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="职业" prop="userType">
        <el-select v-model="formInline.userType" placeholder="">
          <el-option label="人力资源管理师" :value="1" />
          <el-option label="健康管理师" :value="2" />
          <el-option label="数控铣工" :value="3" />
          <el-option label="钳工" :value="4" />
          <el-option label="汽车机械维修工" :value="5" />
          <el-option label="数控车床" :value="6" />
          <el-option label="电工" :value="7" />
        </el-select>
      </el-form-item>
      <el-form-item label="考级" prop="level">
        <el-select v-model="formInline.level" placeholder="请选择考级">
          <el-option label="一级" :value="1" />
          <el-option label="二级" :value="2" />
          <el-option label="三级" :value="3" />
          <el-option label="四级" :value="4" />
        </el-select>
      </el-form-item>
      <el-form-item label="题型" prop="type">
        <el-select v-model="formInline.type" placeholder="请选择题型">
          <el-option label="单选" :value="1" />
          <el-option label="判断" :value="3" />
          <el-option label="多选" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="题目" prop="question">
        <el-input v-model="formInline.question" placeholder="请输入题目" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="onReset(searchFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table-box">
    <div class="table-btns">
      <el-button type="primary" @click="addFn">新增</el-button>
      <el-button
        type="danger"
        :disabled="selectionTable.length == 0"
        @click="batchDelFn"
        >批量删除</el-button
      >
      <el-button type="primary" @click="uploadFn">导入</el-button>
    </div>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <el-table-column type="selection" align="center" width="55" />
      <el-table-column prop="userTypeText" label="职业" align="center" />
      <el-table-column
        prop="question"
        label="题目"
        align="left"
        min-width="300"
      >
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="scope.row.question"
          >
            <template #reference>
              <div class="table-item">
                <el-tag class="tag">{{ ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"][scope.row.level] }}级</el-tag
                ><el-tag class="tag">{{ scope.row.typeText }}</el-tag
                >{{ scope.row.question }}
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="正确选项" align="center">
      </el-table-column>
      <el-table-column prop="selectA" label="(A)选项一" align="center">
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="scope.row.selectA"
          >
            <template #reference>
              <div class="table-item">{{ scope.row.selectA }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="selectB" label="(B)选项二" align="center">
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="scope.row.selectB"
          >
            <template #reference>
              <div class="table-item">{{ scope.row.selectB }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="selectC" label="(C)选项三" align="center">
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="scope.row.selectC"
          >
            <template #reference>
              <div class="table-item">{{ scope.row.selectC }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="selectD" label="(D)选项四" align="center">
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="scope.row.selectD"
          >
            <template #reference>
              <div class="table-item">{{ scope.row.selectD }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="selectE" label="(E)选项五" align="center">
        <template #default="scope">
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            :content="scope.row.selectE"
          >
            <template #reference>
              <div class="table-item">{{ scope.row.selectE }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" width="200">
        <template #default="scope">
          <el-button type="primary" size="small" @click="infoFn(scope.row)"
            >详情</el-button
          >
          <el-button type="primary" size="small" @click="editFn(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" size="small" @click="delFn(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[10, 50, 100, 200]"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <el-dialog
    v-model="modelDialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :width="600"
    title="导入"
  >
    <modelDialog
      v-if="modelDialogVisible"
      ref="modelDialogRef"
      @dialogClose="dialogClose"
    />
  </el-dialog>
  <el-dialog
    v-model="addEditDialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :width="600"
    :title="addEditDialogTitle"
  >
    <multipleChoiceAddEditDialog
      ref="addEditDialogRef"
      v-if="addEditDialogVisible"
      @dialogClose="dialogClose"
    />
  </el-dialog>
  <el-dialog
    v-model="infoDialogVisible"
    append-to-body
    :close-on-click-modal="false"
    :width="800"
    title="详情"
  >
    <multipleChoiceInfoDialog v-if="infoDialogVisible" ref="infoDialogRef" />
  </el-dialog>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import multipleChoiceAddEditDialog from "./dialog/multipleChoiceAddEditDialog.vue";
import multipleChoiceInfoDialog from "./dialog/multipleChoiceInfoDialog.vue";
import modelDialog from "./dialog/modelDialog.vue";
import { selectQuestionList, selectQuestionDelete } from "@/http/http";
let infoDialogVisible = ref(false);
let addEditDialogVisible = ref(false);
let modelDialogVisible = ref(false);
let addEditDialogTitle = ref("");
let infoDialogRef = ref(null);
let addEditDialogRef = ref(null);
let modelDialogRef = ref(null);
const tableData = ref([]);
let selectionTable = ref([]);
const addFn = () => {
  addEditDialogVisible.value = true;
  addEditDialogTitle.value = "新增";
};
const editFn = (row) => {
  addEditDialogVisible.value = true;
  addEditDialogTitle.value = "修改";
  nextTick(() => {
    addEditDialogRef.value.init(row);
  });
};
const infoFn = (row) => {
  infoDialogVisible.value = true;
  nextTick(() => {
    infoDialogRef.value.init(row);
  });
};
const uploadFn = () => {
  modelDialogVisible.value = true;
};
const batchDelFn = () => {
  ElMessageBox.confirm("请确认是否删除？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    console.log(selectionTable.value);
    let ids = [];
    selectionTable.value.forEach((item) => {
      ids.push(item.id);
    });
    _selectQuestionDelete(ids.join());
  });
};
const delFn = (row) => {
  ElMessageBox.confirm("请确认是否删除？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    _selectQuestionDelete(row.id);
  });
};
const _selectQuestionDelete = (ids) => {
  selectQuestionDelete(ids).then((res) => {
    if (res.data.code == 200) {
      ElMessage({
        message: res.data.message,
        type: "success",
      });
      _selectQuestionList();
    }
  });
};
const dialogClose = (flag) => {
  addEditDialogVisible.value = false;
  modelDialogVisible.value = false;
  if (flag) {
    onSubmit();
  }
};

const formInline = reactive({
  userType: 1,
  question: "",
  type: "",
  level: 3,
});
const searchFormRef = ref();
const onSubmit = () => {
  _selectQuestionList();
};
const onReset = (formEl) => {
  formEl.resetFields();
  pageSize.value = 10;
  currentPage.value = 1;
  _selectQuestionList();
};
const format = (percentage) =>
  percentage === 100 ? "已完成" : `${percentage * 10}/1000`;
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(0);
const handleSizeChange = (val) => {
  pageSize.value = val;
  _selectQuestionList();
};
const handleCurrentChange = (val) => {
  currentPage.value = val;
  _selectQuestionList();
};
const handleSelectionChange = (val) => {
  selectionTable.value = val;
};
const _selectQuestionList = () => {
  selectQuestionList({
    size: pageSize.value,
    current: currentPage.value,
    ...formInline,
  }).then((res) => {
    tableData.value = res.data.data.records;
    total.value = res.data.data.total;
  });
};

onMounted(() => {
  _selectQuestionList();
});
</script>
<style scoped lang="scss">
.table-item {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
