<!-- 登录 -->
<template>
  <div class="app">
    <div class="login container">
      <div class="title">学习中心后台管理</div>
      <div class="list flex justify-content_flex-between">
        <img src="../assets/pt.png" alt="" srcset="" />
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="0"
          class="loginForm"
          :size="formSize"
        >
          <el-form-item style="margin-bottom: 22px" prop="userName">
            <el-input
              :prefix-icon="User"
              placeholder="请输入账号"
              v-model="ruleForm.userName"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :prefix-icon="Lock"
              type="password"
              placeholder="请输入密码"
              v-model="ruleForm.password"
            />
          </el-form-item>
          <el-form-item style="margin-top: 40px">
            <el-button type="primary" @click="submitForm(ruleFormRef)">
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="list"></div>
      <div class="copyright">
        Copyright©2023 xiaoyixuexi 备案号：
        <a class="copyrightText" href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023011293号</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { User, Lock } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { login } from "@/http/http";
const formSize = ref("small");
const ruleFormRef = ref();
const ruleForm = reactive({
  userName: "",
  password: "",
});

const rules = reactive({
  userName: [
    {
      required: true,
      message: "请输入用户名",
      trigger: ["change", "blur"],
    },
  ],
  password: [
    {
      required: true,
      message: "请输入密码",
      trigger: ["change", "blur"],
    },
  ],
});
const router = useRouter();
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      login(ruleForm)
        .then((res) => {
          console.log(res);
          $cookies.set("token", res.data.data.authorization);
          router.push({
            path: "/studentManage",
          });
        })
        .catch((err) => {
          console.log(err);
          ElMessage({
            message: err.message,
            type: "error",
          });
        });
    } else {
      console.log("error submit!", fields);
    }
  });
};
</script>

<style scoped lang="scss">
.app {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-tabs {
  width: 340px;
  height: 370px;
  padding: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.349019);
  border-radius: 3px;
  background: #f5f5f5;
}
.title {
  font-size: 35px;
  font-weight: bold;
  color: #0051b7;
  margin: 35px auto;
}
.container {
  width: 900px;
  height: 540px;
  background: #ffffff;
  box-shadow: 0px 12px 45px 0px rgb(82 84 100 / 20%);
  border-radius: 12px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  .list {
    padding: 15px 90px;
    img {
      width: 368px;
      height: 306px;
    }
    .copyright {
      font-size: 12px;
      font-weight: 400;
      color: #0051b7;
      opacity: 0.8;
      margin-top: 14px;
    }
  }
  .loginForm {
    margin-top: 60px;
    .loginTips {
      color: #0051b7;
      opacity: 0.8;
      font-size: 22px;
      font-weight: bold;
      margin: 20px;
    }
    :deep() {
      .el-input--small .el-input__inner {
        height: 36px;
        line-height: 36px;
        width: 250px;
      }
      .el-button--small {
        width: 284px;
        height: 36px;
        border-radius: 4px;
      }
    }
  }
  .copyright{
    margin-top: 20px;
    color: #ccc;
    font-size: 13px;
  }
  .copyrightText{
    color: #ccc;
    font-size: 13px;
  }
  .copyrightText:hover{
    color: #0051b7;
  }
}
</style>
