<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon
  >
    <el-form-item label="职业" prop="userType">
      <el-select v-model="ruleForm.userType" placeholder="">
        <el-option label="人力资源管理师" :value="1" />
        <el-option label="健康管理师" :value="2" />
        <el-option label="数控铣工" :value="3" />
        <el-option label="钳工" :value="4" />
        <el-option label="汽车机械维修工" :value="5" />
        <el-option label="数控车床" :value="6" />
        <el-option label="电工" :value="7" />
      </el-select>
    </el-form-item>
    <el-form-item label="考级" prop="type">
      <el-select v-model="ruleForm.type">
          <el-option label="一级" :value="1" />
          <el-option label="二级" :value="2" />
          <el-option label="三级" :value="3" />
          <el-option label="四级" :value="4" />
      </el-select>
    </el-form-item>
    <el-form-item label="姓名" prop="name">
      <el-input v-model="ruleForm.name" />
    </el-form-item>
    <el-form-item label="性别" prop="sex">
      <el-radio-group v-model="ruleForm.sex">
        <el-radio :label="1">男</el-radio>
        <el-radio :label="2">女</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="手机号" prop="userName">
      <el-input v-model="ruleForm.userName" />
    </el-form-item>
    <el-form-item label="登录密码" prop="password">
      <el-input type="password" v-model="ruleForm.password" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="default" @click="submitForm(ruleFormRef)">
        保存
      </el-button>
      <el-button size="default" @click="closeForm">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
import { userSaveOrUpdate } from "@/http/http";
const formSize = ref("default");
const emit = defineEmits(["dialogClose"]);
const ruleFormRef = ref();
const ruleForm = reactive({
  userType: 1,
  name: "",
  type: "",
  sex: 1,
  userName: "",
  password: "",
});

const rules = reactive({});

const init = (row) => {
  for (const key in row) {
    ruleForm[key] = row[key];
  }
};
defineExpose({ init });

const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      ruleForm.password == "" && delete ruleForm.password;
      userSaveOrUpdate(ruleForm).then((res) => {
        if (res.data.code == 200) {
          ElMessage({
            message: "操作成功",
            type: "success",
          });
          emit("dialogClose", true);
        }
      });
    } else {
      console.log("error submit!", fields);
    }
  });
};

const closeForm = () => {
  emit("dialogClose");
};
</script>
