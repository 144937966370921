<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon
  >
    <el-form-item label="题型" prop="userTypeText">
      <div>{{ ruleForm.userTypeText }}</div>
    </el-form-item>
    <el-form-item label="题型" prop="type">
      <div>{{ ruleForm.typeText }}</div>
    </el-form-item>
    <el-form-item label="考级" prop="level">
      <div>{{ ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"][ruleForm.level] }}级</div>
    </el-form-item>
    <el-form-item label="题目" prop="question">
      <div>
        {{ ruleForm.question }}
      </div>
    </el-form-item>
    <el-form-item label="正确选项" prop="answer">
      <div>{{ ruleForm.answer }}</div>
    </el-form-item>
    <el-form-item label="(A)选项一" prop="o1">
      <div>{{ ruleForm.selectA }}</div>
    </el-form-item>
    <el-form-item label="(B)选项二" prop="o2">
      <div>{{ ruleForm.selectB }}</div>
    </el-form-item>
    <el-form-item
      v-if="ruleForm.type == '1' || ruleForm.type == '2'"
      label="(C)选项三"
      prop="o3"
    >
      <div>{{ ruleForm.selectC }}</div>
    </el-form-item>
    <el-form-item
      v-if="ruleForm.type == '1' || ruleForm.type == '2'"
      label="(D)选项四"
      prop="o4"
    >
      <div>{{ ruleForm.selectD }}</div>
    </el-form-item>
    <el-form-item v-if="ruleForm.type == '2'" label="(E)选项五" prop="o5">
      <div>{{ ruleForm.selectE }}</div>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref } from "vue";
const formSize = ref("default");
const ruleFormRef = ref();
const ruleForm = reactive({
  userTypeText: 1,
  type: "0",
  question: "",
  level: "",
  trueOption: "",
  o1: "",
  o2: "",
  o3: "",
  o4: "",
  o5: "",
});
const init = (row) => {
  for (const key in row) {
    ruleForm[key] = row[key];
  }
};
defineExpose({ init });
</script>
