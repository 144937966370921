<template>
  <el-container>
    <el-header>
      <div class="header flex justify-content_flex-between align-items_center">
        <div>
          <img style="height: 37px;" src="../assets/logo.png" alt="" srcset="">
        </div>
        <div class="title">学习中心后台管理</div>
        <el-icon title="退出登录" size="24" class="icon" @click="handleClose"
          ><SwitchButton
        /></el-icon>
      </div>
    </el-header>
    <el-container>
      <el-aside width="240px">
        <el-menu router :default-active="defaultActive" class="menu">
          <el-menu-item index="/studentManage">
            <el-icon><User /></el-icon>
            <span>学员管理</span>
          </el-menu-item>
          <el-sub-menu index="/home">
            <template #title>
              <el-icon><Collection /></el-icon>
              <span>题库管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/subjectManage/multipleChoice"
                >选择/判断</el-menu-item
              >
              <el-menu-item index="/subjectManage/expoundAnswer"
                >简答/论述/案例分析</el-menu-item
              >
            </el-menu-item-group>
          </el-sub-menu>
          <!-- <el-menu-item index="/home/subjectManage">
            <el-icon><Collection /></el-icon>
            <span>题库管理</span>
          </el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-main><router-view /></el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import { Collection, User, SwitchButton } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import { useRouter,useRoute } from "vue-router";
import { onMounted, ref } from "vue";
const router = useRouter();
const handleClose = () => {
  ElMessageBox.confirm("确认退出登录？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    $cookies.remove("token");
    router.push({
      path: "/login",
    });
  });
};
const defaultActive = ref('/studentManage')
onMounted(() => {
  defaultActive.value = useRoute().path
});
</script>

<style scoped lang="scss">
.el-header {
  padding: 0;
  background-color: #0051b7;
  color: #fff;
  .header {
    height: 100%;
    padding: 0 20px;
    .title {
      font-size: 20px;
    }
    .icon {
      font-size: 24px;
      cursor: pointer;
      padding: 14px;
    }
  }
}
.menu {
  width: 240px;
  height: 100%;
}
.el-main {
  padding: 0;
  text-align: left;
  overflow-y: hidden;
}
.el-menu-item.is-active {
  background-color: #eaeeff;
  color: #0051b7;
}
</style>
