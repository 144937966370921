<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    size="default"
  >
    <el-form-item label="职业" prop="userType">
      <el-select v-model="ruleForm.userType" placeholder="">
        <el-option label="人力资源管理师" :value="1" />
        <el-option label="健康管理师" :value="2" />
        <el-option label="数控铣工" :value="3" />
        <el-option label="钳工" :value="4" />
        <el-option label="汽车机械维修工" :value="5" />
        <el-option label="数控车床" :value="6" />
        <el-option label="电工" :value="7" />
      </el-select>
    </el-form-item>
    <el-form-item label="考级" prop="level">
      <el-select v-model="ruleForm.level" placeholder="">
        <el-option label="一级" :value="1" />
        <el-option label="二级" :value="2" />
        <el-option label="三级" :value="3" />
        <el-option label="四级" :value="4" />
        <el-option label="五级" :value="5" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-upload
        ref="upload"
        accept=".xls,.xlsx"
        :data="{
          userType: ruleForm.userType,
          type: ruleForm.level,
        }"
        :file-list="fileList"
        :show-file-list="false"
        :headers="headers"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :before-upload="beforeUploadFile"
        :action="userImport()"
        v-loading="uploading"
      >
        <el-button type="primary">导入</el-button>
      </el-upload>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import {  userImport, skillAddOrUpdate } from "@/http/http";
const emit = defineEmits(["dialogClose"]);
const ruleFormRef = ref();
const ruleForm = reactive({
  level: 3,
  userType: 1,
});

const rules = reactive({
  level: [
    {
      required: true,
      message: "请选择考级",
      trigger: "change",
    },
  ],
  userType: [
    {
      required: true,
      message: "请选择职业",
      trigger: "change",
    },
  ],
});

let importType = ref(1);
let fileList = ref([]);
let headers = ref({
  token: $cookies.get("token"),
});
let uploading = ref(false);
const beforeUploadFile = (file) => {
  uploading.value = true;
  const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
  if (extension != "xls" && extension != "xlsx") {
    ElMessage({
      message: "只能导入excel文件",
      type: "error",
    });
    uploading.value = false;
  }
  return extension == "xls" || extension == "xlsx";
};
const uploadSuccess = (response, file, fileList) => {
  uploading.value = false;
  if (response.code == 200) {
    ElMessage({
      message: "导入成功",
      type: "success",
    });
    emit("dialogClose", true);
  } else {
    ElMessage({
      message: "导入失败,请尝试重新导入或联系管理员",
      type: "error",
    });
  }
};
const upload = ref();
const uploadError = () => {
  uploading.value = false;
  upload.value.clearFiles();
  ElMessage({
    message: "导入失败,请尝试重新导入或联系管理员",
    type: "error",
  });
};
</script>
<style scoped lang="scss">
.add-item {
  margin: -10px 0 20px 100px;
}
</style>
