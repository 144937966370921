import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
const service = axios.create({
    headers: {
        post: {
            "Content-Type": "application/json;charset=utf-8"
        }
    },
    timeout: 20000
})
let _loading = null;
service.interceptors.request.use(
    config => {
        _loading = ElLoading.service({
            lock: true,
            fullscreen: true,
            spinner: 'el-icon-loading',
            text: "努力加载中..."
        });
        // 请求头
        if ($cookies.get('token')) {
            config.headers['Authorization'] = `${$cookies.get('token')}` || '';
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    res => {
        _loading && _loading.close();
        // 登录成功 token 过期统一返回登录页
        if (res.data.code === 401) {
            $cookies.remove("token");
            window.location.href = '/';
            return
        }
        if (res.data.code != 200) {
            ElMessage.error(res.data.message)
            return
        }
        return res
    },
    error => {
        //    message.error("网络开小差，请稍后再试")
        _loading && _loading.close();
        return Promise.reject(error)
    }
)

export default service