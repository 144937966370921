import { createApp } from "vue";
import ElementPlus from "element-plus";
import router from "./router";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "./assets/css/dufault.css";
import './assets/css/element-variables.scss'
import App from "./App.vue";

const app = createApp(App).use(router);

app.use(ElementPlus, {
  locale: zhCn,
});

// 全局使用cookies
import VueCookies from 'vue-cookies'
app.use(VueCookies)

app.mount("#app");
