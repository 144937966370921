import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/login.vue'
import Home from '../views/home.vue'
import StudentManage from '../views/studentManage/index.vue'
import MultipleChoice from '@/views/subjectManage/multipleChoice.vue'
import ExpoundAnswer from '@/views/subjectManage/expoundAnswer.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/studentManage',
        name: 'studentManage',
        component: StudentManage,
      },
      {
        path: '/subjectManage/multipleChoice',
        name: 'multipleChoice',
        component: MultipleChoice,
      },
      {
        path: '/subjectManage/expoundAnswer',
        name: 'expoundAnswer',
        component: ExpoundAnswer,
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	//     next()  放行    next('/login')  强制跳转
  console.log($cookies);
	if (to.path === '/login') return next()
	// 获取token
	let cookie = $cookies.get('token')
	if (!cookie) return next('/login')
	next()
})

export default router
