<template>
  <div class="search-box">
    <el-form ref="searchFormRef" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="职业" prop="userType">
        <el-select v-model="formInline.userType" placeholder="">
          <el-option label="人力资源管理师" :value="1" />
          <el-option label="健康管理师" :value="2" />
          <el-option label="数控铣工" :value="3" />
          <el-option label="钳工" :value="4" />
          <el-option label="汽车机械维修工" :value="5" />
          <el-option label="数控车床" :value="6" />
          <el-option label="电工" :value="7" />
        </el-select>
      </el-form-item>
      <el-form-item label="考级" prop="level">
        <el-select v-model="formInline.level" placeholder="请选择考级">
          <el-option label="一级" :value="1" />
          <el-option label="二级" :value="2" />
          <el-option label="三级" :value="3" />
          <el-option label="四级" :value="4" />
        </el-select>
      </el-form-item>
      <el-form-item label="题型" prop="type">
        <el-select v-model="formInline.type" placeholder="请选择题型">
          <el-option label="简答" :value="1" />
          <el-option label="论述" :value="2" />
          <el-option label="案例分析" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="题目" prop="question">
        <el-input v-model="formInline.question" placeholder="请输入题目" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="onReset(searchFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="collapse-box">
    <div class="collapse-btns">
      <el-button type="primary" @click="addFn">新增</el-button>
      <el-button type="danger" @click="batchDel">批量删除</el-button>
    </div>
    <el-collapse>
      <el-collapse-item :name="index" v-for="(item, index) in cardData">
        <template #title>
          <div class="flex justify-content_flex-between align-items_center item">
            <div>
              <el-checkbox @change="checkboxChange(item)">{{ index + 1 }}、&nbsp;&nbsp;</el-checkbox>
              <el-tag class="tag">{{ item.userTypeText }}</el-tag>
              <el-tag class="tag">{{
                ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"][
                item.level
                ]
              }}级</el-tag>
              <el-tag class="tag">{{
                item.type == 1 ? "简答" : item.type == 2 ? "论述" : "案例分析"
              }}</el-tag><span>{{ item.question }}</span>
            </div>
            <div class="item-btns">
              <el-button type="primary" @click.stop="editFn(item)" size="small">修改</el-button>
              <el-button type="danger" @click.stop="delFn(item)" size="small">删除</el-button>
            </div>
          </div>
        </template>
        <div>
          <template v-if="item.type == 1 || item.type == 2">
            <div class="analysis">解析</div>
            <div v-if="item.list">{{ item.list[0].keyValue }}</div>
          </template>
          <div class="question" v-else v-for="(t, i) in item.list" :key="i">
            <div class="font-bold">问题{{ i + 1 }}</div>
            {{ t.keyName }}
            <div class="analysis">解析</div>
            {{ t.keyValue }}
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <el-dialog v-model="addEditDialogVisible" append-to-body :close-on-click-modal="false" :width="600"
    :title="addEditDialogTitle">
    <expoundAnswerAddEditDialog v-if="addEditDialogVisible" ref="addEditDialogRef" :type="type"
      @dialogClose="dialogClose" />
  </el-dialog>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import expoundAnswerAddEditDialog from "./dialog/expoundAnswerAddEditDialog.vue";
import { skillList, skillDelete } from "@/http/http";

let addEditDialogRef = ref(null);
let addEditDialogVisible = ref(false);
let addEditDialogTitle = ref("");
let type = ref(null);
const addFn = () => {
  addEditDialogVisible.value = true;
  addEditDialogTitle.value = "新增";
};
const editFn = (row) => {
  addEditDialogVisible.value = true;
  addEditDialogTitle.value = "修改";
  nextTick(() => {
    addEditDialogRef.value.init(row);
  });
};
let checkboxList = [];
const checkboxChange = (item) => {
  console.log(item);
  if (checkboxList.includes(item.id)) {
    checkboxList = checkboxList.filter(t => t !== item.id);
  } else {
    checkboxList.push(item.id)
  }
  console.log(checkboxList);
}
const batchDel = () => {
  if (checkboxList.length < 1) {
    ElMessage({
      message: "请选择需要删除的题目",
      type: "warning",
    });
    return;
  }
  ElMessageBox.confirm("请确认是否批量删除？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    skillDelete(checkboxList.join(",")).then((res) => {
      if (res.data.code == 200) {
        ElMessage({
          message: res.data.message,
          type: "success",
        });
        _skillList();
      }
    });
  });
}
const delFn = (row) => {
  ElMessageBox.confirm("请确认是否删除？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    closeOnClickModal: false,
  }).then(() => {
    skillDelete(row.id).then((res) => {
      if (res.data.code == 200) {
        ElMessage({
          message: res.data.message,
          type: "success",
        });
        _skillList();
      }
    });
  });
};
const dialogClose = (flag) => {
  addEditDialogVisible.value = false;
  if (flag) {
    onSubmit();
  }
};
const formInline = reactive({
  userType: 1,
  question: "",
  type: "",
  level: 3,
});
const searchFormRef = ref();
const onSubmit = () => {
  _skillList();
};
const onReset = (formEl) => {
  formEl.resetFields();
  _skillList();
};
const cardData = ref([]);
const _skillList = () => {
  skillList({
    ...formInline,
  }).then((res) => {
    cardData.value = res.data.data;
  });
};
onMounted(() => {
  _skillList();
});
</script>
<style scoped lang="scss">
.item {
  width: 100%;

  .item-btns {
    min-width: 140px;
    text-align: center;
  }
}
::v-deep .el-checkbox__label{
  padding-left: 3px;
}
</style>
