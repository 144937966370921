<template>
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon
  >
    <el-form-item label="职业" prop="userType">
      <el-select v-model="ruleForm.userType" placeholder="">
        <el-option label="人力资源管理师" :value="1" />
        <el-option label="健康管理师" :value="2" />
        <el-option label="数控铣工" :value="3" />
        <el-option label="钳工" :value="4" />
        <el-option label="汽车机械维修工" :value="5" />
        <el-option label="数控车床" :value="6" />
        <el-option label="电工" :value="7" />
      </el-select>
    </el-form-item>
    <el-form-item label="考级" prop="level">
      <el-select v-model="ruleForm.level" placeholder="">
        <el-option label="一级" :value="1" />
        <el-option label="二级" :value="2" />
        <el-option label="三级" :value="3" />
        <el-option label="四级" :value="4" />
      </el-select>
    </el-form-item>
    <el-form-item label="题型" prop="type">
      <el-select
        v-model="ruleForm.type"
        @change="questionTypeChange(ruleFormRef, ruleForm.type)"
        placeholder=""
      >
        <el-option label="简答" :value="1" />
        <el-option label="论述" :value="2" />
        <el-option label="案例分析" :value="3" />
      </el-select>
    </el-form-item>
    <el-form-item label="题目" prop="question">
      <el-input
        v-model="ruleForm.question"
        :autosize="{ minRows: 3, maxRows: 6 }"
        type="textarea"
      />
    </el-form-item>
    <el-form-item
      v-if="ruleForm.type == 1 || ruleForm.type == 2"
      label="解析"
      prop="keyValue"
    >
      <el-input
        v-model="ruleForm.list[0].keyValue"
        :autosize="{ minRows: 3, maxRows: 6 }"
        type="textarea"
      />
    </el-form-item>
    <template v-if="ruleForm.type == 3" v-for="(item, index) in ruleForm.list">
      <el-form-item :label="'问题' + (index + 1)" prop="keyName">
        <el-input v-model="item.keyName" />
      </el-form-item>
      <el-form-item label="解析" prop="keyValue">
        <el-input
          v-model="item.keyValue"
          :autosize="{ minRows: 3, maxRows: 6 }"
          type="textarea"
        />
      </el-form-item>
    </template>
    <el-button
      v-if="ruleForm.type == 3"
      title="新增问题、解析"
      class="add-item"
      @click="addItem"
      size="small"
      :icon="Plus"
    />
    <el-form-item>
      <el-button type="primary" size="default" @click="submitForm(ruleFormRef)">
        保存
      </el-button>
      <el-button size="default" @click="closeForm">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { skillAddOrUpdate } from "@/http/http";
const formSize = ref("default");
const emit = defineEmits(["dialogClose"]);
const ruleFormRef = ref();
const ruleForm = reactive({
  userType: 1,
  level: "",
  type: 1,
  question: "",
  list: [
    {
      keyName: "",
      keyValue: "",
    },
  ],
});

const rules = reactive({});
const questionTypeChange = (formEl, val) => {
  // formEl.resetFields();
  ruleForm.type = val;
};

const init = (row) => {
  for (const key in row) {
    ruleForm[key] = row[key];
  }
};

defineExpose({ init });

const addItem = () => {
  ruleForm.list.push({
    keyName: "",
    keyValue: "",
  });
};

const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    console.log(valid);
    if (valid) {
      skillAddOrUpdate(ruleForm).then((res) => {
        if (res.data.code == 200) {
          ElMessage({
            message: "操作成功",
            type: "success",
          });
          emit("dialogClose", true);
        }
      });
    } else {
      console.log("error submit!", fields);
    }
  });
};

const closeForm = () => {
  emit("dialogClose");
};
</script>
<style scoped lang="scss">
.add-item {
  margin: -10px 0 20px 100px;
}
</style>
