import request from "./request";
let baseURL = process.env.VUE_APP_BASE_URL;

export function login(data) {
  return request({
    url: baseURL + "/api/admin/login",
    method: "post",
    data,
  });
}
export function userList(data) {
  return request({
    url: baseURL + "/api/admin/user/list",
    method: "post",
    data,
  });
}
export function userSaveOrUpdate(data) {
  return request({
    url: baseURL + "/api/admin/user/saveOrUpdate",
    method: "post",
    data,
  });
}
export function userDelete(ids) {
  return request({
    url: baseURL + "/api/admin/user/delete/" + ids,
    method: "post",
  });
}
export function userImport() {
  return baseURL + "/api/admin/user/import";
}
export function selectQuestionList(data) {
  return request({
    url: baseURL + "/api/selectQuestion/list",
    method: "post",
    data,
  });
}
export function selectQuestionAddOrUpdate(data) {
  return request({
    url: baseURL + "/api/selectQuestion/addOrUpdate",
    method: "post",
    data,
  });
}
export function selectQuestionDelete(ids) {
  return request({
    url: baseURL + "/api/selectQuestion/delete/" + ids,
    method: "post",
  });
}
export function questionImport() {
  return baseURL + "/api/admin/question/import";
}
export function skillList(data) {
  return request({
    url: baseURL + "/api/skill/list",
    method: "post",
    data,
  });
}
export function skillAddOrUpdate(data) {
  return request({
    url: baseURL + "/api/skill/addOrUpdate",
    method: "post",
    data,
  });
}
export function skillDelete(id) {
  return request({
    url: baseURL + "/api/skill/delete/" + id,
    method: "post",
  });
}
